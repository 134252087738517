<template>
<div class="bind flex_center" v-if="upLevel[level]!=undefined"  @touchmove.prevent @mousewheel.prevent>
      <div class="mask" @click="close"></div>
      <div class="bind_bg" style="background:#fff;" :style="$setWidth=='100%'?'max-width: 90%;':'max-width: 500px;'">
        <div class="bind_box y_center">
          <div class="bind_text" style="margin: 10px;display: flex;">
            <div>{{$t('Miner.text29')}}</div>
          </div>
          <div class="bind_text" style="margin: 10px;display: flex;">
            <div :style="'color:'+(upLevel[level].count>info.childCount?'#ff0000':'#0fcd3c')">{{$t('Miner.text30')}}{{upLevel[level].count}}{{$t('Miner.text31')}}</div><div>({{info.childCount}})</div>
          </div>
          <div class="bind_text" style="margin: 10px;display: flex;">
            <div :style="'color:'+(upLevel[level].ng>(Number(user.minerCount)+Number(user._minerCount_390))?'#ff0000':'#0fcd3c')">{{$t('Miner.text32')}}{{upLevel[level].ng}}{{$t('Miner.text33')}}</div><div>({{Number(user.minerCount)+Number(user._minerCount_390)}})</div>
          </div>
          <div class="bind_text" style="margin: 10px;display: flex;">
            <div :style="'color:'+(upLevel[level].power>user.power&&upLevel[level].ng>user._minerCount_390?'#ff0000':'#0fcd3c')">{{$t('Miner.text39')}}{{upLevel[level].power}}T</div><div>({{user.power}})</div>
          </div>
          <div class="bind_text" style="margin: 10px;display: flex;" v-if="upLevel[level].team>0">
            <div  :style="'color:'+(Number(upLevel[level].team)>Number(info.powerTeam)?'#ff0000':'#0fcd3c')">{{$t('Miner.text34')}}{{upLevel[level].team}}T</div><div>({{info.powerTeam}})</div>
          </div>
          <div class="bind_text" style="margin: 10px;display: flex;">
            <div :style="'color:'+(upLevel[level].mt_lp>user.userLpMt?'#ff0000':'#0fcd3c')">LP{{$t('Miner.text35')}}-MT   {{upLevel[level].mt_lp}}U</div><div>({{user.userLpMt}})</div>
          </div>
          <div class="bind_text" style="margin: 10px;display: flex;">
            <div :style="'color:'+(upLevel[level].ulm_lp>user.userLpUlm?'#ff0000':'#0fcd3c')">LP{{$t('Miner.text35')}}-ULME  {{upLevel[level].ulm_lp}}U</div><div>({{user.userLpUlm}})</div>
          </div>
          <div class="bind_text" style="margin: 10px;display: flex;">
            <div :style="'color:'+(upLevel[level].mt_pledge>user.userPledgeMt?'#ff0000':'#0fcd3c')">{{$t('Miner.text35')}}   {{upLevel[level].mt_pledge}}MT</div><div>({{user.userPledgeMt}})</div>
          </div>
          <div class="bind_text" style="margin: 10px;display: flex;">
            <div :style="'color:'+(upLevel[level].ulm_pledge>user.userPledgeUlm?'#ff0000':'#0fcd3c')">{{$t('Miner.text35')}}  {{upLevel[level].ulm_pledge}}ULME</div><div>({{user.userPledgeUlm}})</div>
          </div>
          <div class="bind_text" v-if="level>0" style="margin: 10px;display: flex;">
            <div :style="'color:'+(NFT=='0'?'#ff0000':'#0fcd3c')">MTG质押1枚</div><div>({{NFT}})</div>
          </div>
          <!-- <button @click="updataLevel">升级</button> -->
        </div>
      </div>
    </div>
</template>
 
<script>
import walletHelper from "@/utils/walletHelper.js"

export default {
  props:['info','user','level','NFT'],
  name: 'UpLevelData',
  data () {
    return {
    }
  },
  computed: {
    upLevel(){
      return this.$store.state.user.levelconfig
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init() {
      if(this.upLevel.length==0){
        walletHelper.getLevelconfig()
      }
    },
    close(){
      this.$emit('close')
    }
  }
}
</script>
 
<style lang="scss" scoped>
  .bind{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 1000;
    font-family: PingFang SC;
    .bind_bg{
      background: #21C7D5;
      padding: 6px;
      border-radius: 7px;
      position: relative;
      z-index: 2;
      width: 90%;
      .bind_box{
        // background: rgba(#000000,0.7);
        z-index: 1000;
        padding: 15px 10px;
        border-radius: 7px;
        font-size: 16px;
        .bind_text{
          color: #CE0F0F;
          font-size: 14px;
          padding: 0 5px;
          margin-bottom: 5px;
        }
        .bind_text1{
          color: rgb(14, 245, 137);
          font-size: 14px;
          padding: 0 5px;
          margin-bottom: 10px;
        }
        .bind_text2{
          color: rgb(233, 99, 22);
          font-size: 14px;
          padding: 0 5px;
          margin-bottom: 10px;
        }
        input{
          font-size: 16px;
          // width: 0;
          // flex: 1;
          width: 100%;
          padding: 15px 15px;
          background: #ffffff;
          border-radius: 3px;
          // min-height: 100px;
          color: #00DEFF;
          line-height: 18px;
          margin: 15px 0 10px;
        }
        // chrome
        input::-webkit-input-placeholder { /* WebKit browsers */
          color: #999999;
          font-size:15px;
        }
        // firefox
        input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
          color: #999999;
          font-size:15px;
        }
        button{
          background: #ffffff;
          color: #177C85;
          font-size: 14px;
          border: none;
          // margin-left: 10px;
          border-radius: 3px;
          height: 40px;
          // width: 80px;
          width: 100%;
        }
      }
    }
  }
</style>